import React, { Fragment, useContext } from 'react';
import { Route, HashRouter } from "react-router-dom"
import AboutMe from './components/about-me/AboutMe';
import Answer from './components/answer/Answer';
import Articel from './components/articel/Articel';
import MainPage from './components/main_page/MainPage';
import Navbar from './components/navbar/Navbar';
import Overly from './components/overly/Overly';
import Profile from './components/profile/Profile';
import SArticle from './components/s-article/SArticle';
import Upload from './components/upload/Upload';
import { Context } from './container/Context';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';

const App = () => {
  const context=useContext(Context)
  const {overly,setOverly}=context

  return (
    <Fragment>
      <NotificationContainer/>
      {overly ? <Overly close_overly={() => { setOverly(false) }} /> : null}
      <Navbar open_overly={() => { setOverly(true) }} />

      <HashRouter>
        <Route path="/" exact component={MainPage} />
        <Route path="/article" exact component={Articel} />
        <Route path="/about_me" exact component={AboutMe} />
        <Route path="/upload" exact component={Upload} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/s_article" component={SArticle} />
        <Route path="/answer" component={Answer} />
      </HashRouter>
    </Fragment>
  );
}

export default App;
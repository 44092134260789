import React, { useEffect, useState } from 'react';
import _questio from './question';

import "./question.scss"
const Question = ({set_index}) => {

    const [question, setQuestion] = useState({})

    useEffect(() => {
        _questio.fetch(setQuestion,set_index)
    },[set_index])

    const inner_html = () => {
        return <div id="question_body" dangerouslySetInnerHTML={{ __html: question?.question }} />
    }
    return (
        <div className='question container' id="question">
            <div className="title">
                سوال امروز
            </div>
            <div className="asked-by">
                سوال مطرح شده توسط شرکت: <span>{question?.asked_by}</span>
            </div>
            <div className="question-box">
            {inner_html()}
            </div>
        </div>
    );
}

export default Question;
import _helper from "../../container/helper"

const _main_page={
    scroll_to_question(){
        let offset=document.getElementById("question").offsetTop
        window.scroll({
            top:offset,
            behavior:"smooth"
        })
    },
    
    redirect_to_answer(index){
        console.log(index);
        let token=localStorage.getItem("token")
        if(!token){
           return _helper.notif("برای مشاهده جواب لطفا وارد شوید یا ثبت نام کنید","info")
        }
        _helper.redirect(`answer/${index}`)
    }
}

export default _main_page
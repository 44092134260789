import _helper from "../../container/helper"

const _profile = {
    async featch(setUser) {
        let token = localStorage.getItem("token") || ""
        const data = await _helper.server_get_request(`user/user_info/${token}`)
        if (!data.status) {
            window.location.hash = "/"
            localStorage.removeItem("token")
            return
        }
        else {
            setUser(data.user)
        }
    },

    async notif(setUser, op) {
        let token = localStorage.getItem("token") || ""
        const data = await _helper.server_post_request(`user/notif`, { token, op })
        if (!data.status) {
            window.location.hash = "/"
            localStorage.removeItem("token")
            return
        }
        this.featch(setUser)
    },

    async skills(setUser,skills_list,setEdit,setSkills){
        let token = localStorage.getItem("token") || ""
        const data = await _helper.server_post_request(`user/edit_skills`, { token, skills:skills_list })
        if (!data.status) {
            window.location.hash = "/"
            localStorage.removeItem("token")
            return
        }
        this.featch(setUser)
        setEdit(false)
        setSkills([])
    },
    exit_acc(setUpdateNav){
        localStorage.removeItem("token")
        _helper.redirect("")
        setUpdateNav(prv=>!prv)
    }
}

export default _profile
import React, { Fragment, useEffect, useState } from 'react';
import _helper from '../../container/helper';
import Footer from '../footer/Footer';
import "./articel.scss"
import _article from './article';
const Articel = () => {


    const [artiles, setarticles] = useState([])

    useEffect(() => {

        _article.fetch(setarticles)

    }, [])
    return (
        <Fragment>
            <div className='articel'>
                <div className="articel-container">
                    <div className="content container">
                        <div className="article-lits">
                            {artiles.length > 0 ?artiles.map(article => {
                                const { title, img, date ,_id} = article
                                return (
                                    <div className='each-article' key={_id} onClick={()=>{_helper.redirect(`s_article/${_id}`)}}>
                                        <div className="img">
                                            <img src={img} alt="" />
                                        </div>
                                        <div className="info">
                                            <div className="title">
                                                {title}
                                            </div>
                                            <div className="date eng">
                                                {_helper.date_handler(date)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }):
                            <div className='no-article'>
                                مقاله ای جهت نمایش وجود ندارد
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default Articel;
import React from 'react';
import "./footer.scss"
const Footer = () => {
    return (
        <div className='footer'>
            <div className="text">
            ویدیو های حل مسائل روزانه در شبکه های اجتماعی من:

            </div>
            <div className="links">
                <i className="fab fa-youtube"/>
                <i className="fab fa-instagram"/>
            </div>
        </div>
        
    );
}

export default Footer;
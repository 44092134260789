export const _navbar = {
    toggle() {
        return document.querySelector("#navbar").classList.toggle("active")
    },
    check_loged_in(setIsLogIn) {
        let token = localStorage.getItem("token")
        if (token) return setIsLogIn(true)
        setIsLogIn(false)
    },
    close_nav(){
        return document.querySelector("#navbar").classList.remove("active")

    }
}
import _helper from "../../container/helper"

const _upload={
    upload_handler(e,setFile){
        let file=e.target.files[0]
        if(file)setFile(file)
    },
    async upload(file){
        const payload=new FormData()
        payload.append("file",file)
        let title=document.querySelector("#title").value
        payload.append("title",title)
        let password=localStorage.getItem("password")||""
        const data=await _helper.server_post_request("article/admin/upload_file",payload,{password})
        console.log(data );
    }
}

export default _upload
import _helper from "../../container/helper"

const _s_article = {
    async fetch(props,setArticle) {
        let url = props.location.pathname
        url = url.split("/")
        let id = url[2]
        if (!id) return window.location.hash = ""
        const data = await _helper.server_get_request(`article/s/${id}`)
        setArticle(data.data)
    }
}

export default _s_article
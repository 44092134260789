import _helper from "../../container/helper"

const _questio={
    async fetch(setState,set_index){
        const data=await _helper.server_get_request("challenge")
        setState(data.active_challenge)
        set_index(data.active_challenge.index)
    }
}


export default _questio
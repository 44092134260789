import React, { useState } from 'react';
import "./overly.scss"
import Phone from './stages/Phone';
import SignUp from './stages/SignUp';
import VCode from './stages/VCode';
const Overly = ({ close_overly }) => {


    const [stage, setStage] = useState(0)
    const [phone, setPhone] = useState(null)
    const [signUp,setSignUp]=useState(false)

    const componetns = [
        <Phone change_stage={setStage} set_phone={setPhone} close_overly={close_overly} sign_type={setSignUp} />,
        <VCode change_stage={setStage} phone={phone} close_overly={close_overly} signUp={signUp}/>,
        <SignUp close_overly={close_overly} phone={phone} />
    ]



    return (
        <div>
            <div className="overly">
                <div className="contnet">
                    {componetns[stage]}
                </div>
            </div>
        </div>
    );
}

export default Overly;
import React, { useState } from 'react';
import _upload from './upload';
import "./upload.scss"
const Upload = () => {


    const [file,setFile]=useState(null)

   
    return (
        <div className='upload'>
            <div className="content">
                <div className="title">
                    Upload file to server
                </div>
                <div className="file-input">
                    <input type="file" className="input-file" 
                    onDrop={(e)=>{_upload.upload_handler(e,setFile)}} 
                    onChange={(e)=>{_upload.upload_handler(e,setFile)}} 
                    multiple={false}/>                    
                    <div className="file-title">
                        <input type="text" placeholder='file title' id="title"/>
                    </div>
                    <div className="button">
                        <button onClick={()=>{_upload.upload(file)}}>Upload</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Upload;
import React, { useContext, useState } from 'react';
import { Context } from '../../../container/Context';
import _overly from '../overly';

const SignUp = ({phone,close_overly}) => {

    const [skills,setSkills]=useState([])
    const [notifMe,setNotifMe]=useState(false)

    const context=useContext(Context)

    return (
        <div className='sign-up'>
             <div className="head">
                <div className="title">
                    مشخصات خود را وارد کنید
                </div>
                <div className="close" onClick={close_overly}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
            <div className="inputs rtl">
                <div className="input">
                    <input type="text" placeholder='نام و نام خانوادگی' id="name" autoFocus={true}/>
                </div>
                <div className="sub-title">
                    حوزه کاری شما:
                </div>
                <div className="skills">
                    {_overly.skills_list.map(skill=>{
                        return (
                            <div className='skill' key={skill}>
                                <input type="checkbox" onClick={()=>{_overly.toggle_skill(skill,skills,setSkills)}}/>{skill}
                            </div>
                        )
                    })}
                </div>
               <div className='rtl notif'>
               <input type="checkbox" onClick={()=>{setNotifMe(!notifMe)}}/> در صورت ثبت سوال جدید , مایل به دریافت پیامک هستم
               </div>
            </div>
            <div className="button">
               <button onClick={()=>{_overly.sign_up(notifMe,skills,phone,close_overly,context.setUpdateNav)}}> ثبت اطلاعات</button>
            </div>
        </div>
    );
}

export default SignUp;
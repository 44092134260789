import React, { useEffect, useState } from 'react';
import _s_article from './s-article';
import "./s-article.scss"
const SArticle = (props) => {

    const [article, setArticle] = useState({})

    useEffect(() => {
        _s_article.fetch(props, setArticle)
    }, [props])

    const set_inner_html=()=>{
        return <div className='article-body' dangerouslySetInnerHTML={{__html:article.body}}/>
    }

    return (
        <div className='s-article'>
            <div className="articel-container">
                <div className="content container">
                    <div className="img">
                        <img src={article.img} alt="" />
                    </div>
                    <div className="title">
                        {article.title}
                    </div>
                    {set_inner_html()}
                </div>
            </div>
        </div>
    );
}

export default SArticle;
import _helper from "../../container/helper"

const _overly = {
    v_code(element, index, phone, change_stage, close_overly, sign_up, setUpdateNav) {
        let inputs = document.querySelectorAll(".v-code-input")
        if (element.key === "Backspace") {
            element.target.value = ""
            inputs[index - 1]?.select()
            return
        }
        if (element.target.value.length > 1) {
            element.target.value = element.target.value[0]
            return
        }
        if (index === inputs.length - 1) return this.submit(phone, change_stage, close_overly, sign_up, setUpdateNav)

        else {
            inputs[index + 1]?.focus()
        }
    },
    async submit(phone, change_stage, close_overly, sign_up, setUpdateNav) {
        let inputs = document.querySelectorAll(".v-code-input")
        let code = ""
        for (let input of inputs) { code += input.value }
        try {
            const data = await _helper.server_post_request(`user/${sign_up ? "sign_up_token" : "log_in"}`, { phone, code })
            if(!data.status)throw new Error()
            if (data.token) {
                localStorage.setItem("token", data.token)
                setUpdateNav(prv => { return !prv })
                close_overly()
                _helper.notif("خوش آمدید!", "success")

                return
            }
            change_stage(prv => { return prv + 1 })
            return
        }
        catch {
            _helper.notif("کد تایید شاتباه است", "error")
            return this.reset()
        }

    },
    reset() {
        let inputs = document.querySelectorAll(".v-code-input")
        for (let input of inputs) {
            input.value = ""
        }
        inputs[0].focus()
    },
    async handel_send_code(setStage, sign_type, set_phone) {
        let phone = document.querySelector("#phone").value
        set_phone(phone)
        try {
            const data = await _helper.server_post_request('user', { phone })
            sign_type(data.sign_up)
            setStage(prv => { return prv + 1 })

        }
        catch {
            _helper.notif("شماره تماس نامعتبر است", "error")
            document.getElementById("phone").value = ""
        }
    },
    skills_list: [
        "وب",
        "اندروید",
        "آیفون",
        "بلاک چین",
        "ویندوز فرم",
        'شبکه',
    ],
    toggle_skill(skill, cur_list, set_list) {
        let is_exist = cur_list.indexOf(skill)
        if (is_exist > -1) {
            set_list(prv => { return prv.filter(e => e !== skill) })
        }
        else {
            set_list(prv => { return prv.concat(skill) })
        }
    },
    async sign_up(notif_me, skills, phone, close_overly, setUpdateNav) {
        let name = document.querySelector("#name").value
        if (!name || name === "") return _helper.notif("لطفا نام و نام خانوادگی خود را وارد کنید", "error")
        const data = await _helper.server_post_request(`user/sign_up`, { skills, phone, notif_me, name })
        if (data.status) {
            const { token } = data
            localStorage.setItem("token", token)
            close_overly()
            setUpdateNav(prv => { return !prv })
            _helper.notif("خوش آمدید!", "success")
            return
        }
    }


}

export default _overly
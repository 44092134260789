import React, { Fragment, useContext, useEffect } from 'react';
import { Context } from '../../../container/Context';
import _overly from '../overly';

const VCode = ({ phone, change_stage, close_overly, signUp }) => {

    const context = useContext(Context)


    useEffect(() => {
        let inputs = document.querySelectorAll(".v-code-input")
        inputs.forEach((input, index) => {
            input.dataset.index = index
            input.addEventListener("keyup", (e) => {
                _overly.v_code(e, index, phone, change_stage, close_overly, signUp, context.setUpdateNav)
            })
        })
    })

    return (
        <Fragment>
            <div className="head">
                <div className="title">
                    کد تایید ارسال شده به شماره تماس را وارد کنید
                </div>
                <div className="close" onClick={close_overly}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
            <div className="v-code">
                <div className="v-inputs">
                    <input type="number" className='v-code-input' autoFocus={true}/>
                    <input type="number" className='v-code-input' />
                    <input type="number" className='v-code-input' />
                    <input type="number" className='v-code-input' />
                    <input type="number" className='v-code-input' />
                </div>
            </div>
        </Fragment>
    );
}

export default VCode;
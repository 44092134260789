import React, { useContext, useEffect, useState } from 'react';
import "./navbar.scss"
import { _navbar } from './navbar';
import { Context } from '../../container/Context';
import _helper from '../../container/helper';
const Navbar = ({ open_overly }) => {
    const [isLogIn, setIsLogIn] = useState(false)
    const context=useContext(Context)
    useEffect(()=>{
        _navbar.check_loged_in(setIsLogIn)
    },[context.updatNav])


    return (
        <div className='navbar'>
            <div className="container content" id="navbar">
                <div className="toggle" onClick={_navbar.toggle}>
                    <i className="fas fa-bars " />
                </div>
                <ul className="nav-links">
                    <li onClick={()=>{_helper.redirect("");_navbar.close_nav()}}>صفحه اصلی</li>
                    <li onClick={()=>{_helper.redirect("article");_navbar.close_nav()}}>مقالات</li>
                    <li onClick={()=>{_helper.redirect("about_me");_navbar.close_nav()}}>درباره من</li>
                </ul>
                {!isLogIn ?
                    <div className="registion" onClick={()=>{open_overly();;_navbar.close_nav()}}>
                        ورود / ثبت نام
                    </div> :
                    <div className="registion" onClick={()=>{_helper.redirect("profile")}}>
                        پروفایل
                    </div>
                }
            </div>
        </div>
    );
}

export default Navbar;
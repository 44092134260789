import _helper from "../../container/helper"

const _answer={
    async fetch(props,setAnswer){
        let token=localStorage.getItem("token")
        let url = props.location.pathname
        url = url.split("/")
        let index = url[2]
        if (!index) return window.location.hash = ""
        const data = await _helper.server_get_request(`challenge/answer/${index}`,{token})
        setAnswer(data)
    },

    check_token(){
        let token=localStorage.getItem("token")
        if(!token){
            _helper.redirect("/")
            _helper.notif("برای مشاهده جواب لطفا وارد شوید یا ثبت نام کنید","info")
            return
        }
    }
}


export default _answer
import React, { useEffect, useState } from 'react';
import _helper from '../../container/helper';
import _answer from './answer';
import "./answer.scss"

const Answer = (props) => {


    const [answer, setSnswer] = useState({})


    useEffect(() => {

        _answer.fetch(props, setSnswer)

    }, [props])

    const q_inner_html = () => {
        return (<div className='question'
            dangerouslySetInnerHTML={{ __html: answer.yesterday_challenge?.question }} />)
    }
    const a_inner_html = () => {
        return (<div
            dangerouslySetInnerHTML={{ __html: answer.yesterday_answer?.answer }} />)
    }

    return (
        <div className='answer'>
            <div className="articel-container">
                <div className="content container">
                    <div className="answer-content">
                        <div className="label">
                            سوال:
                        </div>
                        {q_inner_html()}
                        <div className="label">
                            جواب:
                        </div>
                        <div className="video">
                            ویدیو حل مسئله از کانال یوتیوب من (vpn خودتون رو روشن کنید):
                            <hr/>
                            <iframe
                                src={answer.yesterday_answer?.url}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen={true}>
                            </iframe>
                        </div>
                        <div className="ans">
                            {a_inner_html()}
                        </div>
                        <div className="next-prv">
                            <span
                                className={`${!answer.allow_next ? "deactive" : ""}`}
                                onClick={answer.allow_next ?
                                    () => { _helper.redirect(`answer/${answer.yesterday_answer?.index + 2}`) }
                                    : () => { }
                                }
                            >سوال بعد
                            </span>
                            {answer.yesterday_answer?.index + 1}
                            <span
                                onClick={answer.allow_prv ?
                                    () => { _helper.redirect(`answer/${answer.yesterday_answer?.index}`) }
                                    : () => { }
                                }
                                className={`${!answer.allow_prv ? "deactive" : ""}`}
                            >سوال قبل
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Answer;
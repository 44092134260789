import React, { Fragment } from 'react';
import _overly from '../overly';

const Phone = ({ change_stage, sign_type, set_phone, close_overly }) => {
    return (
        <Fragment>
            <div className="head">
                <div className="title">
                    شماره تماس خود را وارد کنید
                </div>
                <div className="close" onClick={close_overly}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault()
                _overly.handel_send_code(change_stage, sign_type, set_phone)
            }}>

                <div className="inputs">
                    <div className="input">
                        <input type="number" id="phone" autoFocus={true} />
                    </div>
                </div>
                <div className="button">
                    <button type='submit'> ارسال کد تایید</button>
                </div>
            </form>
        </Fragment>

    );
}

export default Phone;
export const _about_me = {
    skills_list: [
        {
            title: "HTML/CSS",
            img: "https://cdn-icons-png.flaticon.com/512/5968/5968242.png"
        },
        {
            title: "JavaScript",
            img: "https://icon-library.com/images/javascript-icon-png/javascript-icon-png-23.jpg"
        },
        {
            title: "React.js",
            img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
        },
        {
            title: "Next.js",
            img: "https://static-00.iconduck.com/assets.00/next-js-icon-512x512-zuauazrk.png"
        },
        {
            title: "Node.js",
            img: "https://cdn.iconscout.com/icon/free/png-256/node-js-1174925.png"
        },
        {
            title: "Web3",
            img: "https://compile.blog/wp-content/uploads/2021/11/web3-icon.png"
        },
        {
            title: "MongoDB",
            img: "https://cdn.iconscout.com/icon/free/png-256/mongodb-5-1175140.png"
        },
        {
            title: "Express.js",
            img: "https://cdn.icon-icons.com/icons2/2699/PNG/512/expressjs_logo_icon_169185.png"
        },
        {
            title: "Socket.io",
            img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Socket-io.svg/1200px-Socket-io.svg.png"
        },
        {
            title: "REST API",
            img: "https://cdn-icons-png.flaticon.com/512/2165/2165004.png"
        },
        {
            title: "Bash Script",
            img: "https://cdn3.iconfinder.com/data/icons/developer-files-2-add-on/48/v-58-512.png"
        },
       

        {
            title: "Linux Professional Institute",
            img: "https://cdn-icons-png.flaticon.com/512/6124/6124995.png"
        },
       
       

    ],
    works:[
        {
            icon:"https://greendex.exchange/images/logo.png",
            name:"Green Dex",
            dis:"صرافی غیر متمرکز",
            url:""
        },
        {
            icon:"/images/apec.png",
            name:"Apex Exchange",
            dis:"صرافی متمرکز",
            url:""
        },
        {
            icon:"https://greendex.exchange/images/logo.png",
            name:"Green Auto Trader",
            dis:"پلتفرم کپی ترید",
            url:""
        },
        {
            icon:"/images/mafia.png",
            name:"Mafia Verse",
            dis:"اپلیکیشن بازی آنلاین مافیا",
            url:""
        },
        {
            icon:"https://greendex.exchange/images/logo.png",
            name:"Green Media",
            dis:"شبکه اجتماعی بر بستر بلاک چین",
            url:""
        },
        {
            icon:"/images/siren.png",
            name:"Dota2 Siren",
            dis:"اپلیکیشن تحلیل داده بازی Dota2",
            url:""
        },

    ]
}
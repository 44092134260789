import React, { createContext, useState } from 'react'
export const Context = createContext();

const ContextProvider = (props) => {

    const [updatNav,setUpdateNav]=useState(false)
    const [overly,setOverly]=useState(false)

   
    return (
        <Context.Provider value={{
           updatNav,setUpdateNav,
           overly,setOverly
        }}>
            {props.children}
        </Context.Provider>
    )
}


export default ContextProvider
import React, { Fragment } from 'react';
import Footer from '../footer/Footer';
import { _about_me } from "./about-me"
import "./about-me.scss"

const AboutMe = () => {
    return (
        <Fragment>
            <div className='articel'>
                <div className="articel-container">
                    <div className="content container about-me">
                        <div className="title">
                            درباره من
                        </div>
                        <div className="main">
                            <div className="right">
                                سلام!<br />
                                <span>فربود</span> هستم<br/>برنامه نویس خودآموز فول استک با یک دهه سابقه کار حرفه ای<br />
                                در حوزه های مختلف کار می کنم ولی تخصص اصلی من حوزه وب هستش<br />
                                دو سال اخیر رو صرف کار روی بلاک چین و پروژه های غیر متمرکز کردم<br />
                                این وب سایت رو هم برای دور هم جمع کردن و به چالش کشیدن جامعه برنامه نویسی ایران طراحی کردم<br />
                                خوشحال میشم در زمینه کاریمون باهم در ارتباط باشیم!
                                <div className="email">
                                    <i className="fas fa-envelope" /> devfarbod@gmail.com
                                    <ul className="links">
                                        <a href='https://github.com/dev-farbod' rel="noreferrer" target="_blank"><i className="fab fa-github"/></a>
                                        <a href='https://t.me/Farbod_302' target="_blank" rel="noreferrer"><i className="fab fa-telegram"/></a>
                                        <a href='https://wa.me/09036933881' target="_blank"  rel="noreferrer"><i className="fab fa-whatsapp"/></a>
                                    </ul>
                                </div>
                            </div>
                            <div className="left">
                                <div className="img">
                                    <img src="/images/me.png" alt="" />

                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="skills">
                            <div className="title">
                                تخصص های من
                            </div>
                            <ul className="list">
                                {_about_me.skills_list.map(skill =>
                                    <li className='skill' key={skill.title}>
                                        <div className="icon">
                                            <img src={skill.img} alt="" />
                                        </div>
                                        <div className="label eng">
                                            {skill.title}<br/>
                                        </div>
                                    </li>
                                )}

                            </ul>
                        </div>
                        <hr/>
                        <div className="works">
                            <div className="title">
                                پروژه های در دست توسعه 
                            </div>
                            <ul className="list">
                                {_about_me.works.map(skill =>
                                    <li className='skill' key={skill.name}>
                                        <div className="icon">
                                            <img src={skill.icon} alt="" />
                                        </div>
                                        <div className="label eng">
                                            {skill.name}
                                            <div className="dis">{skill.dis}</div>
                                        </div>
                                    </li>
                                )}

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default AboutMe;
import axios from "axios"
import _static from "./static"
import {NotificationManager} from 'react-notifications';

const _helper={
   async server_get_request(path,headers){
        let {data}=await axios.get(`${_static.server}/${path}`,{headers})
        return data
    },
    async server_post_request(path,payload,header){
        let {data}=await axios.post(`${_static.server}/${path}`,payload,{
            headers:header
        })
        return data
    },


    redirect(path){return window.location.hash=path},
    date_handler(d){
        let date=new Date(d)
        return `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
    },
    notif(msg,type){
        NotificationManager[type](msg)
    }
}


export default _helper
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../container/Context';
import Footer from '../footer/Footer';
import _overly from '../overly/overly';
import _profile from './profile';
import "./profile.scss"
const Profile = () => {


    const [edit, setEdit] = useState(false)
    const [skills, setSkills] = useState([])
    const [user, setUser] = useState({})
    const context=useContext(Context)

    useEffect(() => {
        _profile.featch(setUser)
    }, [])


    return (
        <div className='profile'>
            <div className="articel-container">
                <div className="content container">
                    <div>
                    <div className="title">
                        پروفایل
                    </div>
                    <div className="profile-contetn">
                        <div className="label">
                            مشخصات:
                        </div>
                        <div className="text">
                            {user.idenity?.name}
                        </div>
                    </div>
                    <div className="profile-contetn">
                        <div className="label">
                            شماره تماس:
                        </div>
                        <div className="text eng">
                            {user.idenity?.phone}

                        </div>
                    </div>
                    <div className="profile-contetn">
                        <div className="label">
                            حوزه کاری
                        </div>
                        {edit ?
                            <div className='edit'>
                                {_overly.skills_list.map(skill => {
                                    return (
                                        <div className='skill' key={skill}>
                                            <input type="checkbox" onClick={() => { _overly.toggle_skill(skill, skills, setSkills) }} />{skill}
                                        </div>
                                    )
                                })}
                                <span onClick={()=>{_profile.skills(setUser,skills,setEdit,setSkills)}}>تایید</span>
                                <span className='red' onClick={() => { setEdit(false);setSkills([]) }}>انصراف</span>
                            </div>
                            :
                            <div className="text">
                                {user.skills?.join(",")} <span onClick={() => { setEdit(true) }}>ویرایش</span>
                            </div>
                        }
                    </div>

                    <div className="profile-contetn">
                        سرویس اطلاع رسانی پیامکی شما <span className={user.notif_me ? "green" : "red"}>{user.notif_me ? "فعال" : "غیر فعال"}</span> است
                        <span onClick={() => { _profile.notif(setUser, user.notif_me) }}> تغییر وضعیت</span>
                    </div>
                    <div className="exit red" onClick={()=>{_profile.exit_acc(context.setUpdateNav)}}>
                    خروج از حساب کاربری
                    </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Profile;
import React, { Fragment, useState } from 'react';
import Footer from '../footer/Footer';
import Question from '../question/Question';
import _main_page from './main-page';
import "./main-page.scss"
const MainPage = () => {


    const [index,setIndex]=useState(0)
    return (
        <Fragment>
            <div className='main-page'>
                <div className="main-page-content container">
                    <div className="img">
                        <img src="/images/lp.png" alt="" />
                    </div>
                    <div className="text">
                        <div className="title">
                            چالش های روزانه برنامه نویسی
                        </div>
                        <div className="sub-title">
                            هر روز خودت رو با سوالات استخدامی شرکت های بزرگ حوزه تکنولوژی محک بزن
                        </div>
                    </div>
                    <div className="buttons">
                        <div className="buts">
                            <div className='bold' onClick={_main_page.scroll_to_question}>
                                مشاهده سوال امروز
                            </div>
                            <div onClick={()=>{_main_page.redirect_to_answer(index)}}>
                                مشاهده جواب سوال دیروز
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Question set_index={setIndex} />
            <Footer />
        </Fragment>

    );
}

export default MainPage;